import React from "react";
import styles from "./GallerySlider.module.css";
import Image from "next/image";

export default function BtnSlider({ direction, moveSlide }) {
  // console.log(direction, moveSlide);
  return (
    <button
      onClick={moveSlide}
      className={
        direction === "next"
          ? `${styles.btnSlide} ${styles.next}`
          : `${styles.btnSlide} ${styles.prev}`
      }
    >
      <Image
        width={20}
        height={20}
        layout="fixed"
        src={
          direction === "next"
            ? "/images/right-arrow.svg"
            : "/images/left-arrow.svg"
        }
        alt="direction"
      />
    </button>
  );
}
