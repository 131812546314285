import React from 'react';
import Sidenav from 'rsuite/Sidenav';
import Nav from 'rsuite/Nav';
import Dropdown from 'rsuite/Dropdown';

export default function UserLeftLoadingSidebar() {
    return (
        <Sidenav defaultOpenKeys={['']} className="guest-left-sidebar">
            <Sidenav.Body>
                <Nav>
                    <Dropdown eventKey="2" className="dropdownStyle" title="Game Category">
                        <Dropdown.Item eventKey="1-3">
                            <div>
                                <small style={{ color: 'red' }}>Loading Lottery</small>
                            </div>
                            <div>
                                <small style={{ color: 'black' }}>Loading Lottery</small>
                            </div>
                        </Dropdown.Item>

                        <Dropdown.Item eventKey="1-4">
                            <div>
                                <small style={{ color: 'red' }}>Loading Lottery</small>
                            </div>
                            <div>
                                <small style={{ color: 'black' }}>Loading Lottery</small>
                            </div>
                        </Dropdown.Item>
                    </Dropdown>
                </Nav>
            </Sidenav.Body>
        </Sidenav>
    );
}
