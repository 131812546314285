import React from 'react';
import Dropdown from 'rsuite/Dropdown';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { CASHOUT } from 'utils';
import { handleSetLotteryCategory } from 'redux/actions/lotteryActions';
import { useDispatch } from 'react-redux';
import Image from 'next/image';
import styles from './UserLeftSidebar.module.css';

export default function CashoutNav({ data, handleChangeRoute }) {
    const dispatch = useDispatch();

    const renderButton = (props, ref) => {
        return (
            <button {...props} ref={ref} className={styles.renderButtonStyles}>
                <Image
                    unoptimized
                    src={
                        data?.lotteryImages[CASHOUT]?.icon
                            ? data?.lotteryImages[CASHOUT]?.icon
                            : '/images/logo1.jpg'
                    }
                    alt="Games Image"
                    width={30}
                    height={30}
                    layout="fixed"
                />{' '}
                &nbsp; {`Cashout (${data?.cashoutData?.length})`}{' '}
                {props['aria-expanded'] ? <MdArrowDropUp /> : <MdArrowDropDown />}
            </button>
        );
    };

    return (
        <Dropdown className="dropdownStyle" renderToggle={renderButton}>
            {data?.cashoutData?.length > 0 &&
                data?.cashoutData?.map((lottery, index) => (
                    <Dropdown.Item
                        key={lottery?.gameId}
                        onClick={() => {
                            dispatch(handleSetLotteryCategory(lottery?.category));
                            handleChangeRoute(lottery);
                        }}
                    >
                        <div>
                            <small style={{ color: 'red' }}>{lottery?.name}</small>
                        </div>
                        <div>
                            <small style={{ color: 'black' }}>
                                Created On: {new Date(lottery.createdAt).toDateString()}
                            </small>
                        </div>
                    </Dropdown.Item>
                ))}
        </Dropdown>
    );
}
