import React from 'react';
import Sidenav from 'rsuite/Sidenav';
import Nav from 'rsuite/Nav';
import Dropdown from 'rsuite/Dropdown';
import useSWR, { mutate } from 'swr';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import {
    AFRIKENO,
    BINGO_BALLS,
    CASHOUT,
    checkExpiredUserToken,
    EAZY_WIN,
    FIVE_0F_NINETY,
    getUserToken,
    KENO,
    LEGENDARY_LOTTO,
    LOTTO,
    LOTTO_CONTINENTAL,
    MARIO_KENO,
    MEGA_7,
    META_4,
    METRO,
    PERFECT_BALLS,
    POOL_OF_FAME,
    safeJSONParse,
    SALARY_FOR_LIFE,
    SEVEN49,
    SIX_FOUR_NINE,
} from 'utils';
import axios from 'axios';
import {
    handleReSetComboDescription,
    handleSetLotteryCategory,
    handleResetGameBetType,
    handleResetBetSlips,
    handleSelectGame,
} from 'redux/actions/lotteryActions';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import Image from 'next/image';
import styles from './UserLeftSidebar.module.css';
import UserLeftErrorSidebar from './UserLeftErrorSidebar';
import UserLeftLoadingSidebar from './UserLeftLoadingSidebar';
import LottoContinental from './LottoContinental';
import BingoBall from './BingoBall';
import PoolOfFameNav from './PoolOfFameNav';
import MarioKenoNav from './MarioKenoNav';
import Seven49Nav from './Seven49Nav';
import LottoNav from './LottoNav';
import CashoutNav from './CashoutNav';
import MetroNav from './MetroNav';
import Meta4Nav from './Meta4Nav';
import Mega7Nav from './Mega7Nav';
import PerfectBallsNav from './PerfectBallsNav';
import dayjs from 'dayjs';

export default function GameCategoriesNav() {
    const dispatch = useDispatch();
    const router = useRouter();
    const today = dayjs().day();

    const url = `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game`;
    // const handleMutate = async () => await mutate(url);

    // React.useEffect(() => {
    //     handleMutate();
    // }, []);
    const fetcher = async () => {
        const token = getUserToken();
        let res = {};
        const mega7Data = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=mega-7&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const fiveNinetyData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${FIVE_0F_NINETY}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const salary4lifeData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=salary4life&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const legendaryLottoData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=legendarylotto&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const afrikenoLottoData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${AFRIKENO}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const eazyWinLottoData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${EAZY_WIN}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const kenoLottoData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${KENO}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const six49LottoData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${SIX_FOUR_NINE}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const perfectBallsData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${PERFECT_BALLS}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const meta4Data = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${META_4}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const lottoData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${LOTTO}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const metroData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${METRO}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const cashoutData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${CASHOUT}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const seven49LottoData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${SEVEN49}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const poolData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${POOL_OF_FAME}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const lottoContinentalData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${LOTTO_CONTINENTAL}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const marioKenoData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${MARIO_KENO}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        const bingoBallsData = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?limit=20&category=${BINGO_BALLS}&currentWeekDay=${today}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );

        const lotteryImages = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/site-settings/fetch-setting-by-slug/game-categories-with-icons`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );

        const lotteryImagesArray = safeJSONParse(lotteryImages?.data?.data?.data?.content);
        let newContent = {};

        lotteryImagesArray.forEach((element) => {
            newContent[element.name] = element;
        });

        res.mega7Data = mega7Data.data?.data?.data;
        res.fiveNinetyData = fiveNinetyData.data?.data?.data;
        res.salary4lifeData = salary4lifeData.data?.data?.data;
        res.legendaryLottoData = legendaryLottoData?.data?.data?.data;
        res.afrikenoLottoData = afrikenoLottoData?.data?.data?.data;
        res.eazyWinLottoData = eazyWinLottoData?.data?.data?.data;
        res.six49LottoData = six49LottoData?.data?.data?.data;
        res.kenoLottoData = kenoLottoData?.data?.data?.data;
        res.perfectBallsData = perfectBallsData?.data?.data?.data;
        res.meta4Data = meta4Data?.data?.data?.data;
        res.lottoData = lottoData?.data?.data?.data;
        res.metroData = metroData?.data?.data?.data;
        res.cashoutData = cashoutData?.data?.data?.data;
        res.seven49LottoData = seven49LottoData?.data?.data?.data;
        res.poolData = poolData?.data?.data?.data;
        res.lottoContinentalData = lottoContinentalData?.data?.data?.data;
        res.marioKenoData = marioKenoData?.data?.data?.data;
        res.bingoBallsData = bingoBallsData?.data?.data?.data;

        res.lotteryImages = newContent;

        return res;
    };

    const { data, error } = useSWR(url, fetcher);

    if (error) {
        console.log(error);
        return <UserLeftErrorSidebar />;
    }

    if (!data) {
        return <UserLeftLoadingSidebar />;
    }

    const renderSalaryForLifeButton = (props, ref) => {
        return (
            <button {...props} ref={ref} className={styles.renderButtonStyles}>
                <Image
                    unoptimized
                    src={
                        data?.lotteryImages[SALARY_FOR_LIFE]?.icon
                            ? data?.lotteryImages[SALARY_FOR_LIFE]?.icon
                            : '/images/logo1.jpg'
                    }
                    alt="Games Image"
                    width={30}
                    height={30}
                    layout="fixed"
                />{' '}
                &nbsp; {`Salary 4 Life (${data?.salary4lifeData?.length})`}{' '}
                {props['aria-expanded'] ? <MdArrowDropUp /> : <MdArrowDropDown />}
            </button>
        );
    };
    const renderLegendaryLottoButton = (props, ref) => {
        return (
            <button {...props} ref={ref} className={styles.renderButtonStyles}>
                <Image
                    unoptimized
                    src={
                        data?.lotteryImages[LEGENDARY_LOTTO]?.icon
                            ? data?.lotteryImages[LEGENDARY_LOTTO]?.icon
                            : '/images/logo1.jpg'
                    }
                    alt="Games Image"
                    width={30}
                    height={30}
                    layout="fixed"
                />{' '}
                &nbsp; {`Legendary Lotto (${data?.legendaryLottoData?.length})`}{' '}
                {props['aria-expanded'] ? <MdArrowDropUp /> : <MdArrowDropDown />}
            </button>
        );
    };

    const renderFiveNinetyButton = (props, ref) => {
        return (
            <button {...props} ref={ref} className={styles.renderButtonStyles}>
                <Image
                    unoptimized
                    src={
                        data?.lotteryImages[FIVE_0F_NINETY]?.icon
                            ? data?.lotteryImages[FIVE_0F_NINETY]?.icon
                            : '/images/logo1.jpg'
                    }
                    alt="Games Image"
                    width={30}
                    height={30}
                    layout="fixed"
                />{' '}
                &nbsp; {`5 of 90 (${data?.fiveNinetyData?.length})`}{' '}
                {props['aria-expanded'] ? <MdArrowDropUp /> : <MdArrowDropDown />}
            </button>
        );
    };
    const renderAfrikenoButton = (props, ref) => {
        return (
            <button {...props} ref={ref} className={styles.renderButtonStyles}>
                <Image
                    unoptimized
                    src={
                        data?.lotteryImages[AFRIKENO]?.icon
                            ? data?.lotteryImages[AFRIKENO]?.icon
                            : '/images/logo1.jpg'
                    }
                    alt="Games Image"
                    width={30}
                    height={30}
                    layout="fixed"
                />{' '}
                &nbsp; {`Afrikeno (${data?.afrikenoLottoData?.length})`}{' '}
                {props['aria-expanded'] ? <MdArrowDropUp /> : <MdArrowDropDown />}
            </button>
        );
    };

    const renderEazyWinButton = (props, ref) => {
        return (
            <button {...props} ref={ref} className={styles.renderButtonStyles}>
                <Image
                    unoptimized
                    src={
                        data?.lotteryImages[EAZY_WIN]?.icon
                            ? data?.lotteryImages[EAZY_WIN]?.icon
                            : '/images/logo1.jpg'
                    }
                    alt="Games Image"
                    width={30}
                    height={30}
                    layout="fixed"
                />{' '}
                &nbsp; {`Eazy Win (${data?.eazyWinLottoData?.length})`}{' '}
                {props['aria-expanded'] ? <MdArrowDropUp /> : <MdArrowDropDown />}
            </button>
        );
    };

    const renderKenoButton = (props, ref) => {
        return (
            <button {...props} ref={ref} className={styles.renderButtonStyles}>
                <Image
                    unoptimized
                    src={
                        data?.lotteryImages[KENO]?.icon
                            ? data?.lotteryImages[KENO]?.icon
                            : '/images/logo1.jpg'
                    }
                    alt="Games Image"
                    width={30}
                    height={30}
                    layout="fixed"
                />{' '}
                &nbsp; {`kENO (${data?.kenoLottoData?.length})`}{' '}
                {props['aria-expanded'] ? <MdArrowDropUp /> : <MdArrowDropDown />}
            </button>
        );
    };

    const render649Button = (props, ref) => {
        return (
            <button {...props} ref={ref} className={styles.renderButtonStyles}>
                <Image
                    unoptimized
                    src={
                        data?.lotteryImages[SIX_FOUR_NINE]?.icon
                            ? data?.lotteryImages[SIX_FOUR_NINE]?.icon
                            : '/images/logo1.jpg'
                    }
                    alt="Games Image"
                    width={30}
                    height={30}
                    layout="fixed"
                />{' '}
                &nbsp; {`649 (${data?.six49LottoData?.length})`}{' '}
                {props['aria-expanded'] ? <MdArrowDropUp /> : <MdArrowDropDown />}
            </button>
        );
    };

    const handleChangeRoute = (item) => {
        dispatch(handleResetGameBetType());
        dispatch(handleResetBetSlips());
        dispatch(handleReSetComboDescription());
        dispatch(handleSetLotteryCategory(item?.lottery?.category));
        router.push(
            `/lottery/${item?.lotteryId}/${item?.Lottery?.slug}?gameId=${item?.gameId}&lotteryCategory=${item?.Lottery?.category}`,
        );
        dispatch(handleSelectGame(item));
    };
    return (
        <>
            <Mega7Nav data={data} handleChangeRoute={handleChangeRoute} />
            <Dropdown className="dropdownStyle" renderToggle={renderSalaryForLifeButton}>
                {data?.salary4lifeData?.length > 0 &&
                    data?.salary4lifeData?.map((lottery, index) => (
                        <Dropdown.Item
                            key={lottery?.gameId}
                            onClick={() => {
                                dispatch(handleSetLotteryCategory(lottery?.category));
                                handleChangeRoute(lottery);
                            }}
                        >
                            <div>
                                <small style={{ color: 'red' }}>{lottery.name}</small>
                            </div>
                            <div>
                                <small style={{ color: 'black' }}>
                                    Created On: {new Date(lottery.createdAt).toDateString()}
                                </small>
                            </div>
                        </Dropdown.Item>
                    ))}
            </Dropdown>
            <Dropdown
                className="dropdownStyle"
                // title={`Legendary Lotto (${data?.legendaryLottoData?.length})`}
                renderToggle={renderLegendaryLottoButton}
            >
                {data?.legendaryLottoData?.length > 0 &&
                    data?.legendaryLottoData?.map((lottery, index) => (
                        <Dropdown.Item
                            key={lottery?.gameId}
                            onClick={() => {
                                dispatch(handleSetLotteryCategory(lottery?.category));
                                handleChangeRoute(lottery);
                            }}
                        >
                            <div>
                                <small style={{ color: 'red' }}>{lottery.name}</small>
                            </div>
                            <div>
                                <small style={{ color: 'black' }}>
                                    Created On: {new Date(lottery.createdAt).toDateString()}
                                </small>
                            </div>
                        </Dropdown.Item>
                    ))}
            </Dropdown>

            <Dropdown
                className="dropdownStyle"
                // title={`5 of 90 ( ${data?.fiveNinetyData?.length} )`}
                renderToggle={renderFiveNinetyButton}
            >
                {data?.fiveNinetyData?.length > 0 &&
                    data?.fiveNinetyData?.map((lottery, index) => (
                        <Dropdown.Item
                            key={lottery?.gameId}
                            onClick={() => {
                                dispatch(handleSetLotteryCategory(lottery?.category));
                                handleChangeRoute(lottery);
                            }}
                        >
                            <div>
                                <small style={{ color: 'red' }}>{lottery.name}</small>
                            </div>
                            <div>
                                <small style={{ color: 'black' }}>
                                    Created On: {new Date(lottery.createdAt).toDateString()}
                                </small>
                            </div>
                        </Dropdown.Item>
                    ))}
            </Dropdown>
            <Dropdown
                className="dropdownStyle"
                // title={`Afrikeno ( ${data?.afrikenoLottoData?.length} )`}
                renderToggle={renderAfrikenoButton}
            >
                {data?.afrikenoLottoData?.length > 0 &&
                    data?.afrikenoLottoData?.map((lottery, index) => (
                        <Dropdown.Item
                            key={lottery?.gameId}
                            onClick={() => {
                                dispatch(handleSetLotteryCategory(lottery?.category));
                                handleChangeRoute(lottery);
                            }}
                        >
                            <div>
                                <small style={{ color: 'red' }}>{lottery.name}</small>
                            </div>
                            <div>
                                <small style={{ color: 'black' }}>
                                    Created On: {new Date(lottery.createdAt).toDateString()}
                                </small>
                            </div>
                        </Dropdown.Item>
                    ))}
            </Dropdown>
            <Dropdown
                className="dropdownStyle"
                // title={`Eazy Win ( ${data?.eazyWinLottoData?.length} )`}
                renderToggle={renderEazyWinButton}
            >
                {data?.eazyWinLottoData?.length > 0 &&
                    data?.eazyWinLottoData?.map((lottery, index) => (
                        <Dropdown.Item
                            key={lottery?.gameId}
                            onClick={() => {
                                dispatch(handleSetLotteryCategory(lottery?.category));
                                handleChangeRoute(lottery);
                            }}
                        >
                            <div>
                                <small style={{ color: 'red' }}>{lottery.name}</small>
                            </div>
                            <div>
                                <small style={{ color: 'black' }}>
                                    Created On: {new Date(lottery.createdAt).toDateString()}
                                </small>
                            </div>
                        </Dropdown.Item>
                    ))}
            </Dropdown>
            <Dropdown className="dropdownStyle" renderToggle={renderKenoButton}>
                {data?.kenoLottoData?.length > 0 &&
                    data?.kenoLottoData?.map((lottery, index) => (
                        <Dropdown.Item
                            key={lottery?.gameId}
                            onClick={() => {
                                dispatch(handleSetLotteryCategory(lottery?.category));
                                handleChangeRoute(lottery);
                            }}
                        >
                            <div>
                                <small style={{ color: 'red' }}>{lottery.name}</small>
                            </div>
                            <div>
                                <small style={{ color: 'black' }}>
                                    Created On: {new Date(lottery.createdAt).toDateString()}
                                </small>
                            </div>
                        </Dropdown.Item>
                    ))}
            </Dropdown>

            <Dropdown className="dropdownStyle" renderToggle={render649Button}>
                {data?.six49LottoData?.length > 0 &&
                    data?.six49LottoData?.map((lottery, index) => (
                        <Dropdown.Item
                            key={lottery?.gameId}
                            onClick={() => {
                                dispatch(handleSetLotteryCategory(lottery?.category));
                                handleChangeRoute(lottery);
                            }}
                        >
                            <div>
                                <small style={{ color: 'red' }}>{lottery.name}</small>
                            </div>
                            <div>
                                <small style={{ color: 'black' }}>
                                    Created On: {new Date(lottery.createdAt).toDateString()}
                                </small>
                            </div>
                        </Dropdown.Item>
                    ))}
            </Dropdown>

            <PerfectBallsNav data={data} handleChangeRoute={handleChangeRoute} />
            <Meta4Nav data={data} handleChangeRoute={handleChangeRoute} />
            <MetroNav data={data} handleChangeRoute={handleChangeRoute} />
            <CashoutNav data={data} handleChangeRoute={handleChangeRoute} />
            <LottoNav data={data} handleChangeRoute={handleChangeRoute} />
            <Seven49Nav data={data} handleChangeRoute={handleChangeRoute} />
            <PoolOfFameNav data={data} handleChangeRoute={handleChangeRoute} />
            <MarioKenoNav data={data} handleChangeRoute={handleChangeRoute} />
            <LottoContinental data={data} handleChangeRoute={handleChangeRoute} />
            <BingoBall data={data} handleChangeRoute={handleChangeRoute} />
        </>
    );
}
