import React from 'react';
import Sidenav from 'rsuite/Sidenav';
import Nav from 'rsuite/Nav';
import Dropdown from 'rsuite/Dropdown';

import GallerySlider from 'components/LeftGallerySlider/GallerySlider';

export default function UserLeftErrorSidebar() {
    return (
        <Sidenav defaultOpenKeys={['']} className="guest-left-sidebar">
            <Sidenav.Body>
                <Nav>
                    <Dropdown eventKey="1" className="dropdownStyle" title="Game Category">
                        <Dropdown.Item eventKey="1-1">
                            <div>
                                <small style={{ color: 'red' }}>An Error Occured</small>
                            </div>
                            <div>
                                <small style={{ color: 'black' }}>An Error Occured</small>
                            </div>
                        </Dropdown.Item>
                    </Dropdown>
                </Nav>
                <GallerySlider />
            </Sidenav.Body>
        </Sidenav>
    );
}
