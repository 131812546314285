import React from 'react';
import Sidenav from 'rsuite/Sidenav';
import Nav from 'rsuite/Nav';

import {
    handleReSetComboDescription,
    handleSetLotteryCategory,
    handleResetGameBetType,
    handleResetBetSlips,
    handleSelectGame,
} from 'redux/actions/lotteryActions';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import styles from './UserLeftSidebar.module.css';
import GallerySlider from 'components/LeftGallerySlider/GallerySlider';
import DrawsBox from '../DrawsBox/DrawsBox';

import GameCategoriesNav from './GameCategoriesNav';
import { BINGO_BALLS, renderHostURL } from 'utils';

export default function LeftSidebar() {
    const dispatch = useDispatch();
    const router = useRouter();

    const handleChangeRouteByGame = async (item, lottery) => {
        dispatch(handleResetGameBetType());
        dispatch(handleResetBetSlips());
        dispatch(handleReSetComboDescription());
        dispatch(handleSetLotteryCategory(item?.Lottery?.category));

        router.push(
            `/lottery/${item?.lotteryId}/${lottery?.slug}?gameId=${item?.gameId}&lotteryCategory=${lottery?.category}`,
        );
        dispatch(handleSelectGame(item));
    };

    return (
        <Sidenav defaultOpenKeys={['']} className={styles.sideNavCont}>
            <Sidenav.Body>
                <Nav>
                    <DrawsBox handleChangeRouteByGame={handleChangeRouteByGame} />

                    <GameCategoriesNav />
                </Nav>
                <GallerySlider />
            </Sidenav.Body>
        </Sidenav>
    );
}
